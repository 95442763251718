<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {  mapActions } from "vuex";
export default{
  data(){
    return{

    }
  },
  methods:{
    ...mapActions(["getUserInfoData"]),
    // setUserInfo(){
    //   const tenantId = localStorage.getItem('TENANTID') || '';
    //   if(tenantId){
    //     this.getUserInfoData(tenantId);
    //   }
    // },
  },
  created(){
    // this.setUserInfo();
  }
}
</script>
<style lang="scss">
body{
  margin: 0;
  background: #F0F2F5;
}
.el-form-item__label{
  line-height: 14px!important;
}
.el-dialog__body {
  padding: 0 20px 10px!important;
}
.el-dialog__header{
  padding: 10px!important;
}
</style>
